<template>
  <div class="task-list">
    <van-tabs ref="wxbTabs" v-model="active" sticky swipeable @change="change">
      <van-tab title="待执行">
        <div class="list">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-if="taskList.length>0"
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                :offset="100"
            >
              <wxb-task-card @confirm="confirm" v-for="(item,index) of taskList" :info="item" :key="index"
                             :type="item.attachType?item.attachType:3" :status="1"/>
            </van-list>
            <wxb-no-data v-else/>
          </van-pull-refresh>
        </div>
      </van-tab>
      <van-tab title="已完成">
        <div class="list">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-if="taskList.length>0"
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                :offset="100"
            >
              <wxb-task-card @confirm="confirm" v-for="(item,index) of taskList" :info="item" :key="index"
                             :type="item.attachType?item.attachType:3" :status="2"/>
            </van-list>
            <wxb-no-data v-else/>
          </van-pull-refresh>
        </div>
      </van-tab>
      <van-tab title="已逾期">
        <div class="list">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-if="taskList.length>0"
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                :offset="100"
            >
              <wxb-task-card @confirm="confirm" v-for="(item,index) of taskList" :info="item" :key="index"
                             :type="item.attachType?item.attachType:3" :status="3"/>
            </van-list>
            <wxb-no-data v-else/>
          </van-pull-refresh>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import task from '@/api/task';

export default {
  name: 'taskList',
  data () {
    return {
      active: 0,
      // 卡片分页
      pageNation: {
        pageNo: 0,
        pageSize: 20,
        totalRecords: 0,
      },
      loading: false,
      finished: false,
      refreshing: false,
      taskList: [],
    };
  },
  methods: {
    //获取任务列表
    async getTaskList () {
      let params = {
        estateIds: localStorage.getItem('platform_estateId'),
        taskExecStatus: this.active === 2 ? -1 : this.active,
        pageNo: this.pageNation.pageNo,
        pageSize: this.pageNation.pageSize
      };
      let { code, data } = await task.getTaskList(params);
      this.loading = false;
      if (code === '0') {
        let arr=data.data.map(item=>{
          if (item.taskType===1) {
            return {...item.spreadTask, taskType: item.taskType};
          } else if (item.taskType===2) {
            return {...item.addFriendTask, taskType: item.taskType};
          } else {
            return {};
          }
        });
        this.taskList = this.taskList.concat(arr);
        this.pageNation.totalRecords = data.totalRecords;
        if (this.taskList.length >= this.pageNation.totalRecords) {
          this.finished = true;
        }
      } else {
        this.finished = true;
      }
    },
    //下拉刷新
    onRefresh () {
      this.pageNation.pageNo = 0;
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad () {
      if (this.refreshing) {
        this.taskList = [];
        this.refreshing = false;
      }
      this.pageNation.pageNo += 1;
      this.getTaskList();
    },
    async confirm (taskId) {
      let { code } = await task.performTask({ taskId, estateId: localStorage.getItem('platform_estateId') });
      if (code === '0') {
        this.pageNation = {
          pageNo: 0,
          pageSize: 20,
          totalRecords: 0,
        };
        this.taskList = [];
        this.onLoad();
      }
    },

    //标签选择
    change () {
      this.taskList = [];
      this.pageNation = {
        pageNo: 0,
        pageSize: 20,
        totalRecords: 0,
      };
      this.onLoad();
    }
  },
  mounted () {
    this.onLoad();
  }
};
</script>

<style lang="less" scoped>
.task-list {
  .list {
    padding: 12px;
  }
}
</style>
